import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Resume from "../assets/NE_resume.pdf";




const PasswordInput = ({ correctPassword, onAuthenticated }) => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width:800px)",
  });
  const [password, setPassword] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const IMG = (imgName) => {
    return require(`../assets/images/icons/${imgName}`);
  };


  const errorTextStyle = {
    display: "flex",
    fontFamily: "RobotoMono",
    color: "red",
    fontSize: "11px",
    zIndex: "99999",
    marginTop: ".5%"
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorText;
    if (password === correctPassword) {
      onAuthenticated(true);  // Notify the parent that the user is authenticated
      errorText = "";
    } else {
      // alert('Incorrect password.\nPlease try again or reach out to Noah for the password.');
      errorText = 'Incorrect password.\nPlease try again or reach out to Noah for the password.';
      onAuthenticated(false);
      document.getElementById("errorMessage").innerHTML = errorText;
    }
  };

  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let margTop, fontSize;

  if (isBigScreen) {
    fontSize = "1.1rem";
  } else {
    fontSize = "0.9rem";
  }

  if (isMobileDevice) {
    margTop = "6%";
  } else {
    margTop = "3%";
  }

  const FormInputStyle = {
    marginTop: margTop,
    marginBottom: "1%",

    fontFamily: "Courier",
    fontSize: fontSize,
    color: "black",
    // transform: "translate(-50%, 0)",
    marginLeft: "5%",
    textAlign: "left",
    width: "80%",
    textDecoration: "none"
  };

  const ButtonStyle = {
    cursor: 'pointer',
    marginLeft: '.5%'
  }

  const iconStyle = {
    marginTop: "3%",
    width: "1.4em",
    paddingRight: "1.7%",
  };


  return (

    <form style={FormInputStyle} onSubmit={handleSubmit}>
      <p>Enter password to view selected work</p>
      <input
        type="password"
        value={password}
        onChange={handlePasswordChange}
      />
      <button style={ButtonStyle} type="submit">→</button>
      <p style={errorTextStyle} id='errorMessage'></p>
      <div>
          <a
            href="mailto:noedelstein@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={iconStyle}
              className="icon"
              src={IMG("mail.png")}
              alt="email"
            />
          </a>
          <a href={Resume} target="_blank" rel="noopener noreferrer">
            <img
              style={iconStyle}
              className="icon"
              src={IMG("resume.png")}
              alt="resume"
            />
          </a>
          <a
            href="https://github.com/Nedelstein"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={iconStyle}
              className="icon"
              src={IMG("github.png")}
              alt="github"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/noahedelstein5/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={iconStyle}
              className="icon"
              src={IMG("linkedin.png")}
              alt="linkedin"
            />
          </a>
        </div>
    </form>
  );
};

export default PasswordInput;