import React from "react";
import ReactPlayer from "react-player";
import CopyrightFooter from "../components/CopyrightFooter";


const IMG = (imgName) => {
    return require(`../assets/images/${imgName}`);
  };


const LensFest = () => {
    return (
        <div className="page">
            <div className="projectPageTitle">Snapchat Lens Fest</div>
            <div className="projectPageBody">
                Lens Fest, Snapchat's annual AR festival, was helded virtually for the first time in 2020. 
                Open to all, the three-day event convenes Snap's community of external lens creators who use Snap's Lens Studio development toolkit to design AR filters. 
                DE-YAN was hired to design and build the 3D web platform the event was hosted on.
            </div>
            <div className="player-wrapper-versace">
            <ReactPlayer
                className="react-player"
                url="https://vimeo.com/555961094"
                width="100%"
                height="100%"
                controls="true"
            />
            </div>
            <div className="projectPageBody">
                The virtual event situated the user inside the lobby of a 3D building. From there users could navigate to different rooms, each housing a unique experience. There was a live stream, on-demand content, a chat feature, breakout sessions, and an interactive globe space highlighting Snapchat's lens creators. 
            </div>
            <img style={{marginBottom: "2%  "}} className="projectPageImg" src={IMG("lensfest_lobby.png")}>
            </img>
            <img className="projectPageImg" src={IMG("lensfest_globe.png")}>
            </img>
            <CopyrightFooter/>
        </div>
    );
};

export default LensFest;