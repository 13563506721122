import React from "react";
import CopyrightFooter from "../components/CopyrightFooter";
import { Player, ControlBar, Shortcut } from "video-react";


const IMG = (imgName) => {
    return require(`../assets/images/${imgName}`);
  };

  const CashApp = () => {
    return (
        <div className="page">
            <div className="projectPageTitle">Cash App Website</div>
            <div className="projectPageBody">
                We took a fresh look at Cash App's marketing website to build a renewed focus 
                on the company's brand perception and product positioning in an effort to drive 
                further conversion.
            </div>
            <div className="projectPageVideo">
            <Player
            volume={0}
            muted={true}
            playing={true}
            autoPlay={true}
            controls={false}
            loop={true}
            playsInline={true}
            src={IMG("cashApp/CashApp_OurStory.mp4")}>
            <ControlBar disableCompletely={true}></ControlBar>
            <Shortcut clickable={false} />
            </Player>
        </div>
        <div className="projectPageBody">
            We developed a creative direction rooted in three guiding principles: 
            establish trust and credibility, clarify Cash App's product offering 
            by clearly articulating the company's product ecosystem, 
            and build a durable system that enables speed and scale.
        </div>
        <img className="projectPageImg" alt="Cash App Homepage Hero" src={IMG("cashApp/Homeherodesktop.png")}></img>
        <div className="projectPageBody">
        This direction embraces the strategic use of negative space, utilizing radical scaling 
        shifts between content to craft dynamic and engaging compositions.
        <br/><br/>
        By blending expressive typographic layouts, and authentic real-world storytelling 
        we crafted a creative direction that feels real, authentic, and unapologetically Cash App.
        </div>
        <img className="projectPageImg" alt="Product Recommendations Page" src={IMG("cashApp/ProductRecommendationDesktop.png")}></img>
        <br/><br/>
        <img className="projectPageImg" alt="Cash App Nav Menu" src={IMG("cashApp/NavMenuDesktop.png")}></img>
        <br/><br/>
        <img className="projectPageImg" alt="Cash App Banking Page" src={IMG("cashApp/BankingPage.gif")}></img>
        <br/><br/>
        </div>
    )
  };

  export default CashApp;