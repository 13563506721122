import React from "react";

const NotFoundPage = () => {
  return (
    <div className="page">
      <p
        style={{
          fontFamily: "RobotoMono",
          fontSize: "16px",
          marginLeft: "5%",
          marginTop: "4%",
          color: "black",
          width: "70%",
        }}
      >
        404 not found <p>Whoopsie! I don't know if this page exists. Or it's my fault. Sorry!</p>
        <br />{" "}
      </p>
    </div>
  );
};

export default NotFoundPage;
