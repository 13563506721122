import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Switch from "react-switch";
import LoadingOverlay from "react-loading-overlay";
import ScaleLoader from "react-spinners/ScaleLoader";

import PasswordInput from "../components/PasswordInput";
import ProjectsGrid from "../components/ProjectsGrid";
import InkInWater_HomePage from "../components/InkInWater_HomePage";
import InkInWater_HomePage_Alt from "../components/InkInWater_HomePage_Alt";
import LavaLamp_HomePage from "../components/LavaLamp_HomePage";
import LavaLamp_HomePage_Alt from "../components/LavaLamp_HomePage_Alt";

import CopyrightFooter from "../components/CopyrightFooter";

const HomePage = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const correctPassword = 'welcome';
  const pwExpirationTime = 60 * 120 * 1000 //2 hours in milliseconds

  const [inkShader, setInkShader] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [active, isActive] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      isActive(false);
    }, 1000);
  }, []);

  useEffect(()=> {
    const storedAuthStatus = localStorage.getItem('isAuthenticated');
    const storedTime = localStorage.getItem('authTime');

    if(storedAuthStatus && storedTime) {
      const currentTime = new Date().getTime();
      if (currentTime - storedTime < pwExpirationTime){
        setIsAuthenticated(true);
      } else {
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('authTime');
      }
    }
  }, []);

  const handleAuthentication = (authStatus) => {
    setIsAuthenticated(authStatus);
    if (authStatus) {
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('authTime', new Date().getTime()); // Store the current time
    }
  };

  window.addEventListener(
    "unload",
    () => {
      isActive(true);
    },
    false
  );

  const isMobileDevice = useMediaQuery({
    query: "(max-width:940px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let fontSize, margTop;

  if (isMobileDevice) {
    fontSize = "0.8rem";
    margTop = "6%";
  } else if (isBigScreen) {
    fontSize = "1.2rem";
    margTop = "1.5%";
  } else {
    fontSize = "1rem";
    margTop = "3%";
  }


  let projTitle = document.getElementsByClassName("projTitle");
  const onToggleChange = () => {
    if (toggle === false) {
      setToggle(true);
      setInkShader(true);
      document.getElementById("name").style.color = "white";
      document.getElementById("links").style.color = "white";
      document.getElementById("introText").style.color = "white";
      document.getElementById("toggleText").style.color = "white";
      for (let i = 0; i < projTitle.length; i++) {
        projTitle[i].style.color = "white";
      }
    } else {
      setToggle(false);
      setInkShader(false);
      document.getElementById("name").style.color = "inherit";
      document.getElementById("links").style.color = "black";
      document.getElementById("introText").style.color = "black";
      document.getElementById("toggleText").style.color = "black";
      for (let i = 0; i < projTitle.length; i++) {
        projTitle[i].style.color = "black";
      }
    }
  };

  const introText = {
    fontFamily: "RobotoMono",
    fontSize: fontSize,
    marginLeft: "5%",
    // width: "60%",
    marginTop: margTop,
    marginBottom: "2%",
    lineHeight: "27px",
    position: "sticky",
  };

  const toggleDiv = {
    textAlign: "right",
    marginBottom: "5%",
    marginTop: "2%",
    marginRight: "3%",
  };

  const toggleTextStyle = {
    fontFamily: "RobotoMono",
    fontSize: "0.8rem",
    // verticalAlign: "super",
    position: "relative",
  };

  const spinnerStyle = {
    display: "block",
    margin: "0, auto",
    paddingBottom: "15px",
  };

  const ShaderBackground = () => {
    let shaderArry = [
      InkInWater_HomePage,
      InkInWater_HomePage_Alt,
      LavaLamp_HomePage,
      LavaLamp_HomePage_Alt,
    ];
    let RandomArray = shaderArry[Math.floor(Math.random() * shaderArry.length)];
    return (
      <div style={{ position: "fixed", top: "0" }}>
        <RandomArray />
      </div>
    );
  };

  return (
    <>
      <LoadingOverlay
        active={active}
        spinner={<ScaleLoader css={spinnerStyle} size={200} color={"#fff"} />}
        fadeSpeed={500}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "#000",
            position: "fixed",
          }),
          content: (base) => ({
            ...base,
            fontFamily: "RobotoMono",
            marginTop: "30%",
            left: "50%",
          }),
        }}
      >
         <div>
      {isAuthenticated ? (
        <div>
          {/* PW protected content */}
          <div className="page">
          {inkShader && <ShaderBackground />}
          <div id="introText" style={introText}>
            A creative & technical producer building immersive and
            interactive environments.
          </div>
          <div
            style={{
              // marginBottom: "4%",
              marginLeft: "5%",
              // zIndex: "99999",
            }}
          >
            <ProjectsGrid />
          </div>
          <div style={toggleDiv}>
            <Switch
              onChange={onToggleChange}
              checked={toggle}
              onColor="#00FFFF"
              onHandleColor="#FF0080"
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={25}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 5px rgba(0, 255, 255, 0.2)"
              height={15}
              width={55}
            ></Switch>
            <div id="toggleText" style={toggleTextStyle}>
              turbo mode
            </div>
          </div>
          <CopyrightFooter />
        </div>
        </div>
      ) : (
        <PasswordInput
          correctPassword={correctPassword}
          onAuthenticated={handleAuthentication}
        />
      )}
    </div>
        {/* <div className="page">
          {inkShader && <ShaderBackground />}
          <div id="introText" style={introText}>
            A creative producer building immersive and
            interactive environments.
          </div>
          <div
            style={{
              // marginBottom: "4%",
              marginLeft: "5%",
              // zIndex: "99999",
            }}
          >
            <ProjectsGrid />
          </div>
          <div style={toggleDiv}>
            <Switch
              onChange={onToggleChange}
              checked={toggle}
              onColor="#00FFFF"
              onHandleColor="#FF0080"
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={25}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 5px rgba(0, 255, 255, 0.2)"
              height={15}
              width={55}
            ></Switch>
            <div id="toggleText" style={toggleTextStyle}>
              turbo mode
            </div>
          </div>
          <CopyrightFooter />
        </div> */}
      </LoadingOverlay>
    </>
  );
};

export default HomePage;
